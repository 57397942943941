/* eslint-disable react/no-unknown-property */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

function ContactUsForm() {
  const {
    register,
    setError,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = async (data, e) => {
    setIsSuccess(false);
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    try {
      const token = await executeRecaptcha("yourAction");
      console.log(data, token);
      const result = await axios({
        method: "post",
        url: "https://cloudalgo.io/api/contactFormApi",
        data: {
          ...data,
          "g-recaptcha-response": token,
        },
      });
      setErrorMessage(null);
      setIsSuccess(true);
      reset();
    } catch (error) {
      console.log(error);
      const { response, message } = error;
      if (message) {
        setErrorMessage(message);
      }
      console.log(response.data);
      if (response?.data?.error && Array.isArray(response.data.error)) {
        response.data.error.forEach((err) => {
          setError(
            err.context.key,
            { type: "custom", message: err.message },
            { shouldFocus: true },
          );
        });
      }
    }
  };

  return (
    <>
      <form
        className="mb-5"
        method="post"
        id="contactForm"
        name="contactForm"
        noValidate="noValidate"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-md-12 form-group">
            <input
              type="text"
              className="form-control"
              name="firstName"
              id="firstName"
              placeholder="Your first name"
              maxLength={100}
              {...register("firstName", {
                required: true,
                maxLength: {
                  value: 100,
                  message: "First name must be less than 100 characters",
                },
              })}
            />
            {errors.firstName && (
              <span className="error-line">
                {errors.firstName.message || "First name is required"}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 form-group">
            <input
              type="text"
              className="form-control"
              name="lastName"
              id="lastName"
              placeholder="Your last name"
              maxLength={100}
              {...register("lastName", {
                required: true,
                maxLength: {
                  value: 100,
                  message: "Last name must be less than 100 characters",
                },
              })}
            />
            {errors.lastName && (
              <span className="error-line">
                {errors.lastName.message || "Last name is required"}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email"
              maxLength={100}
              {...register("email", {
                required: true,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid email address",
                },
                maxLength: {
                  value: 100,
                  message: "Email must be less than 100 characters",
                },
              })}
            />
            {errors.email && (
              <span className="error-line">
                {errors.email.message || "Email is required"}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 form-group">
            <textarea
              className="form-control"
              name="message"
              id="message"
              cols="30"
              rows="7"
              placeholder="Write your message"
              maxLength={500}
              {...register("message", {
                required: true,
                maxLength: {
                  value: 500,
                  message:
                    "Too Many Characters. Message must be less than 500 characters",
                },
              })}
            ></textarea>
            {errors.message && (
              <span className="error-line">
                {errors.message.message || "Message is required"}
              </span>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <button type="submit" className="btn btn-secondary  py-2 px-4">
              Send Message
              <span className="icon-arrow_forward"></span>
            </button>
          </div>
        </div>
      </form>
      <div id="form-message-warning mt-4">{errorMessage}</div>
      {isSuccess && (
        <div id="form-message-success">Your message was sent, thank you!</div>
      )}
    </>
  );
}

export default ContactUsForm;
